import * as React from "react";
import PageLayout from "../../PageLayout";
import SectionLayout from "../../SectionLayout";
import { Helmet } from "react-helmet";
import background from "../../images/watermanagement/banner.jpg"
import HowWeCanHelp from "../../components/HowWeCanHelp";
import {Gallery} from "../../components/Gallery";
import img1 from "../../images/watermanagement/1.jpg";
import img2 from "../../images/watermanagement/2.jpg";
import img3 from "../../images/watermanagement/3.jpg";
import img4 from "../../images/watermanagement/4.jpg";
import img5 from "../../images/watermanagement/5.jpg";
import img6 from "../../images/watermanagement/6.jpg";
import img7 from "../../images/watermanagement/7.jpg";
import img8 from "../../images/watermanagement/8.jpg";
import img9 from "../../images/watermanagement/9.jpg";
import img10 from "../../images/watermanagement/10.jpg";
import img11 from "../../images/watermanagement/11.jpg";


const images = [
    {
        original: img1,
    },
    {
        original: img2,
    },
    {
        original: img3,
    },
    {
        original: img4,
    },
    {
        original: img5,
    },
    {
        original: img6,
    },
    {
        original: img7,
    },
    {
        original: img8,
    },
    {
        original: img9,
    },
    {
        original: img10,
    },
    {
        original: img11,
    },
];
const WaterManagement = () => {
    return (
        <PageLayout
            noButton
            image={background}
            title={"Water Management"}
            navSize={"h-96"}
            titleStyles={"xl:mt-12 lg:mt-20 md:mt-24 sm:mt-24 mt-20"}
        >
            <Helmet>
                <title>
                    EPG Solutions | Water Management Services
                </title>
                <meta
                    name="description"
                    content="Automated bore control systems and chlorine control systems to give reliable and safe drinking water supply in remote areas."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                <div className={"bg-epg-white py-8"}>
                    <p className={"mt-5 leading-7 lg:leading-8 lg:text-lg font-exo2 text-epg-black"}>
                        We are experienced in building and commissioning automated bore
                        control systems and chlorine control systems to ensure the reliable
                        supply and safety of water resources in remote areas.
                    </p>
                    <p className={"mt-5 leading-7 font-exo2 lg:leading-8 lg:text-lg text-epg-black"}>
                        <span className={"font-bold"}>Automated bore control systems </span>
                        can be designed to commence operation remotely, locally, or through a
                        cyclic timer with redundancy. This gives communities ultimate control
                        over how their water resources are managed and controlled.
                    </p>
                    <p className={"mt-5 leading-7 font-exo2 lg:leading-8 lg:text-lg text-epg-black"}>
                        <span className={"font-bold"}>Chlorine control systems</span> ensure
                        accurate chlorine levels are maintained to provide safe drinking water
                        all year round.
                    </p>
                </div>
                <div className={"bg-epg-white pt-5"}>
                    <Gallery images={images} additionalClass={{ height: 200 }} />
                </div>
            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default WaterManagement;
